import '../styles/globals.css'
require('../public/css/style.css');
import '../public/css/geosearch.css'
import 'antd/dist/antd.css';
import '../styles/globals.css'
import { SessionProvider } from "next-auth/react"

function MyApp({ Component, pageProps }) {
  return (
    <SessionProvider>
      <Component {...pageProps} />
    </SessionProvider>
  )
}

export default MyApp
